import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'src/redux'
import tracking from 'src/tracking'

import { FavoriteType, SoloPage } from '../../graphql/generated/api-graphql'
import { wediaImagesTransform } from '../Common/wedia'
import { Icons } from '../../components/atoms/Icon'
import { selectors } from '../../redux'
import { SoloHeroProps } from '../../components/molecules/SoloHero'

const useSoloHero = (data?: SoloPage): SoloHeroProps => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const getFavoriteBrands = useSelector(selectors.auth.favoriteBrands)
  const asFavoriteBrand = getFavoriteBrands.find(
    (item) => item.id === data?.brand?.id
  )

  const isConnected = useSelector(selectors.auth.isConnected)

  const AddWishlist = () => {
    dispatch(
      actions.member.favoriteToggleRequest({
        type: FavoriteType.Brand,
        id: data?.brand.id ?? '',
      })
    )
    tracking.wishlist(FavoriteType.Brand, data?.brand.name, data?.brand.name)
  }

  return {
    banner: {
      images: wediaImagesTransform(data?.headerImage),
      alt: data?.headerImage?.alt ?? ' ',
      maxWidth: 3200,
      width: data?.headerImage?.variations?.[0].width ?? undefined,
      height: data?.headerImage?.variations?.[0].height ?? undefined,
      withHD: true,
      withLazyLoading: false,
    },
    mobileBanner: data?.headerMobileImage
      ? {
          images: wediaImagesTransform(data.headerMobileImage),
          alt: data.headerMobileImage?.alt ?? ' ',
          maxWidth: 2000,
          width: data?.headerMobileImage?.variations?.[0].width ?? undefined,
          height: data?.headerMobileImage?.variations?.[0].height ?? undefined,
          withHD: true,
          withLazyLoading: false,
        }
      : undefined,
    logo: {
      images: wediaImagesTransform(
        data?.brand?.soloLogo ?? data?.brand?.pictureLogo
      ),
      alt:
        data?.brand?.soloLogo?.alt ??
        data?.brand?.pictureLogo?.alt ??
        data?.brand?.name ??
        ' ',
      maxWidth: 540,
      width: 270,
      height: 200,
      withHD: true,
      withLazyLoading: false,
    },
    like: {
      link: {
        label: t('add_to_my_bookmarks'),
        'aria-label': t('add_to_my_bookmarks'),
        onClick: () =>
          isConnected
            ? AddWishlist()
            : dispatch(actions.auth.setAuthPopinOpen(true)),
      },
      icon: {
        icon: asFavoriteBrand ? Icons.heartFull : Icons.heartLine,
      },
    },
  }
}

export default useSoloHero
