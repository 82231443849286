import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { imageMocks } from '../../../components/atoms/Picture/mocks'
import { ActionButtonVariant } from '../../../components/atoms/ActionButton/styled'
import { Icons } from '../../../components/atoms/Icon'
import FormFieldCheckbox from '../../../components/form/fields/FormFieldCheckbox'
import { useServiceRequest } from '../../../hooks/useServiceRequest'
import { actions, selectors } from '../../../redux'
import { MemberUpdateInput } from '../../../graphql/generated/api-graphql'
import { LightAuthOptinModalProps } from '../../../components/molecules/LightAuthModal/LightAuthOptinModal'

export const useCapriceValentineGameOptin = (
  isOpen: boolean,
  closeHandler: () => void
): LightAuthOptinModalProps => {
  const dispatch = useDispatch()
  const [update, updateHandler] = useServiceRequest(
    selectors.member.memberUpdate,
    actions.member.memberUpdateRequest,
    actions.member.memberUpdateReset,
    closeHandler
  )

  useEffect(() => {
    return () => {
      dispatch(actions.member.memberUpdateReset())
    }
  }, [dispatch, isOpen])

  return {
    isOpen,
    closeHandler,
    logoProps: {
      ...imageMocks,
      images: [
        {
          src: '/static/assets/images/caprice/caprice-logo.png',
          size: 400,
        },
      ],
    },
    title: 'Alerte aux bons plans !',
    formikForm: {
      initialValues: {
        cheeseLetter: false,
        partnerLetter: false,
      },
      onSubmit: (values) =>
        updateHandler({
          input: {
            cheeseLetter: values.cheeseLetter,
            partnerLetter: values.partnerLetter,
          } as MemberUpdateInput,
        }),
    },
    errors:
      update.errors && update.errors.length > 0
        ? {
            messages: update.errors,
          }
        : undefined,
    fields: {
      cheeseLetter: {
        name: 'cheeseLetter',
        Component: FormFieldCheckbox,
        label: 'Je souhaite m’inscrire aux cheeseletters Qui veut du Fromage',
      },
    },
    text: 'La cheeseletter c’est un peu le super plateau de bons fromages pour votre boite mail. Tendances culinaires, culture fromagère, idées recettes et conseils nutritionnels, sans oublier nos bons plans promotionnels !',
    legalNotice:
      '<p>Vous allez adhérer au service du site « Qui Veut Du Fromage». Les informations que vous nous avez données sont destinées à permettre la gestion de votre compte, améliorer votre expérience du programme et si vous l’avez accepté, recevoir des lettres d’information. Elles seront conservées pendant 36 mois après inactivité et sont destinées à SAVENCIA PRODUITS LAITIERS FRANCE. Conformément à la Règlementation en vigueur, vous disposez de droits, <a href="/nf/privacy" target="_blank">cliquez ici</a> pour en savoir plus. Pour exercer ces droits, veuillez-nous contacter par mail via <a href="/st/contact" target="_blank">le formulaire de contact</a>.</p><p>En créant votre compte, vous acceptez <a href="/nf/legals" target="_blank">les Conditions Générales d’Utilisation</a>.</p><p><b>Un email vous sera envoyé pour confirmer votre inscription.</b></p>',
    submitButton: {
      isPending: update?.pending,
      text: update?.pending ? 'Validation...' : 'Valider',
      variant: ActionButtonVariant.secondary,
      iconPosition: 'right',
      iconProps: {
        icon: update?.pending ? Icons.refresh : Icons.arrowRight,
      },
    },
  }
}
